import { Invoice } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class InvoiceService extends BaseService {
  /**
   * Fetches all invoices for the person with the specified national id
   *
   * @param {string} personalId - The national id of the person to get invoices for
   * @returns {Promise<Array<Invoice>>} A promise with a list of Invoices
   */
  getInvoices(personalId: string): Promise<Array<Invoice>> {
    const endpointController = "Invoice";
    const endpointRoute = "nationalId";
    const parameterName = "nationalId";
    const parameterArgument = personalId;
    const getInvoices = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<Array<Invoice>>(getInvoices);
  }
}

export default new InvoiceService();
